import React, { useEffect } from "react";
import Projects from "./components/Projects";
import About from "./components/About";
import notFound from "./components/404";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

function App() {
    let path = window.location.href.split("/");

    let location = path[4];
    let type = path[5];
    if (location == null) {
        location = "projects";
    }
    if (type == null) {
        type = "front-end";
    }
/* 
    useEffect(() => {
        if (window.innerWidth > 992) {
            scrollAnimation();
            setInterval(() => {
                requestAnimationFrame(scrollAnimation);
            }, 1000 / 60);
            window.addEventListener("scroll", (e) => {});
        }
    }, []);

    function scrollAnimation() {
        let y = window.scrollY;
        let el = document.querySelectorAll("[data-animate]");
        checkPos(el, y);
    }

    function checkPos(elArray, y) {
        let el = elArray;
        for (let i = 0; i < el.length; i++) {
            let elTop = el[i].getBoundingClientRect().top,
                elLeft = el[i].getBoundingClientRect().left,
                // (vhm = viewHeightMargin)
                vhm = window.innerHeight / 3 * 2,
                // Get the difference from the element's offset and the viewport margin
                difference = elTop - vhm,
                // Get the percentage of the difference from the element's offset, based on the viewport margin
                percentage = (difference * 100) / vhm,
                // Get a value usable with CSS's Opacity and transform: scale, etc, between 0 and 1
                opacity = 1 - percentage / 100,
                translateValue = (elTop - vhm) / 10,
                translateX = translateValue,
                translateY = translateValue;
            if (el[i].tagName === "IMG") {
                translateY = translateY - (translateY * 1.1);
            }
            if (el[i].tagName === "BUTTON" || el[i].tagName === "A") {
                translateY = translateY * 2;
            }
            if (elTop - window.innerHeight / 2 > 0 && elTop < y + window.innerHeight) {
                el[i].style.transform = "translate(0, " + translateY / 2 + "px) scale(1)";
                el[i].style.opacity = opacity;
            } else if (elTop - window.innerHeight / 2 < 0 && elTop < y + window.innerHeight) {
                el[i].style.transform = "translate(0, " + translateY / 2 + "px) scale(1)";
            } else {
                el[i].style.transform = "translate(0, 0)";
                el[i].style.opacity = 1;
            }
        }
    }
 */
    return (
        <Router>
            <div id="wrapper">
                <Nav type={type} />
                <div id="app">
                    <Switch>
                        <Route path="/" exact component={() => <Projects type={type} />} />
                        <Route path="/projects/" component={() => <Projects type={type} />} />
                        <Route path="/about/" component={() => <About type={type} />} />
                        <Route component={notFound} />
                    </Switch>
                </div>
                <Footer type={type} />
            </div>
        </Router>
    );
}

export default App;
