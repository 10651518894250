import React, { useState, useEffect } from "react";
import Project from "./assets/Project";
import { NavLink } from "react-router-dom";

import hero from "../images/illustrations/hero_svg.svg";

import studyDeckThumb from "../images/studydeck-thumbnail.png";
import studyDeckIcon from "../images/isotopes/studydeck/icon/icon.png";

import toWatchListThumb from "../images/thetowatchlist-thumbnail.jpg";
import toWatchListIcon from "../images/isotopes/thetowatchlist/icon/icon.png";

import cheatSheetThumb from "../images/cheatsheet-thumbnail.jpg";
import cheatSheetIcon from "../images/isotopes/cheatsheet/icon/icon.png";

import bingoThumb from "../images/bingo-thumbnail.png";
import bingoIcon from "../images/isotopes/bingo/icon/icon.png";

import scandiThumb from "../images/scandi-thumbnail.jpg";
import scandiIcon from "../images/isotopes/scandi/icon/icon.png";

import twingoThumb from "../images/twingo-thumbnail.jpg";
import jazzThumb from "../images/jazz-thumbnail.png";

function WebProjects(props) {
    const { data } = props;
    window.scrollTo(0, 0);
    document.title = "Projects - Yens Severyns";
    let jobTitle = "Graphic/Web Designer";
    let downloadLink = process.env.PUBLIC_URL + "/downloads/CV_yensseveryns_210531.pdf";


    let WebProjects = [
        {
            title: "StudyDeck",
            key: 'studyDeck-1',
            thumbnail: studyDeckThumb,
            icon: studyDeckIcon,
            text: [
                {
                    title: "Persoonlijk project: ",
                    text: "Een project waar gebruikers zich kunnen aansluiten in klasgroepen, en hierin een proefexamen kunnen opstellen voor elkaar, en deze proef dan kunnen uitvoeren.",
                },
                {
                    title: "Technische info: ",
                    text: "In deze preview kan je niet inloggen, maar wel een test aanmaken, en dit uitproberen. Dus een aantal zaken zijn disabled, en de demo zit op een aparte sectie van de database.",
                },
                {
                    title: "Grafische info: ",
                    text: "Voor dit project heb ik met zeer veel ademruimte gewerkt om een rustige omgeving te creëren zonder veel aandacht-trekkers.",
                },
            ],
            tags: ["React", "Firebase"],
            folder: "studydeck",
            images: [
                {
                    mobile: "studydeck-1.png",
                    desktop: "studydeck-desktop-1.png",
                },
                {
                    mobile: "studydeck-2.png",
                    desktop: "studydeck-desktop-2.png",
                },
                {
                    mobile: "studydeck-3.png",
                    desktop: "studydeck-desktop-3.png",
                },
                {
                    mobile: "studydeck-4.png",
                    desktop: "studydeck-desktop-4.png",
                },
                {
                    mobile: "studydeck-5.png",
                    desktop: "studydeck-desktop-5.png",
                },
                {
                    mobile: "studydeck-6.png",
                    desktop: "studydeck-desktop-6.png",
                },
                {
                    mobile: "studydeck-7.png",
                    desktop: "studydeck-desktop-7.png",
                },
                {
                    mobile: "studydeck-8.png",
                    desktop: "studydeck-desktop-8.png",
                },
                {
                    mobile: "studydeck-9.png",
                    desktop: "studydeck-desktop-9.png",
                },
            ],
            link: "https://studydeck-portfolio.netlify.app",
        },
        {
            title: "The To Watch List",
            key: "theToWatchList-2",
            thumbnail: toWatchListThumb,
            icon: toWatchListIcon,
            text: [
                {
                    title: "Persoonlijk project: ",
                    text: "Een web app waar je films kan toevoegen in een lijst. Die films kan je een rating/review/comment geven naast de rating van IMDb, etc. De data van de films worden ingeladen via OMDb en wordt opgeslagen in een Google Firebase Database waar dan de eigen rating en comments mee verwerkt worden.",
                },
                {
                    title: "Technische info: ",
                    text: "In deze preview kan je niet inloggen, enkel geautoriseerde gebruikers kunnen comments plaatsen en enkel moderators kunnen films toevoegen. Dus een aantal zaken zijn disabled, en de demo zit op een aparte sectie van de database.",
                },
                {
                    title: "Grafische info: ",
                    text: "Voor dit project heb ik met groenblauw gewerkt als accent om een rustige omgeving te creëren op de website zelf. Maar als een modal open staat, heb ik vooral met grijswaarden gewerkt en de cover van de film de sfeer voor zichzelf laten zetten. De achtergrond blur is dan ook donkerder gezet om niet in conflict te komen met de witte tekst.",
                },
            ],
            tags: ["jQuery", "Authentication", "API", "Database"],
            folder: "thetowatchlist",
            images: [
                {
                    mobile: "thetowatchlist-1.jpg",
                    desktop: "thetowatchlist-desktop-1.jpg",
                },
                {
                    mobile: "thetowatchlist-2.jpg",
                    desktop: "thetowatchlist-desktop-2.jpg",
                },
                {
                    mobile: "thetowatchlist-3.jpg",
                    desktop: "thetowatchlist-desktop-3.jpg",
                },
                {
                    mobile: "thetowatchlist-4.jpg",
                    desktop: "thetowatchlist-desktop-4.jpg",
                },
            ],
            link: "https://towatchlist-portfolio.netlify.app",
        },
        {
            title: "Cheatsheet",
            key: "cheatSheet-3",
            thumbnail: cheatSheetThumb,
            icon: cheatSheetIcon,
            text: [
                {
                    title: "Persoonlijk project: ",
                    text: "Een web app waar ik notities en artikelen kan toevoegen. Daarnaast kan ik ook bepaalde eenheden gaan berekenen zoals pixel naar REM of pixel naar CM, gebaseerd op de ingegeven pixel-densiteit. Ook heb ik hier instellingen zoals dark-mode via localStorage gebruikt zodat dit geen extra request nodig heeft.",
                },
                {
                    title: "Technische info: ",
                    text: "Voor de documentatie en de notities heb ik een database van Google Firebase gebruikt. De documentatie is vrij te lezen, maar enkel bewerkbaar door een moderator, gescoped vanuit de database zelf. De notities zijn daarintegen niet vrij te lezen en zijn gescoped per gebruiker.",
                },
                {
                    title: "Grafische info: ",
                    text: "Voor dit project heb ik gekozen om zo minimalistisch mogelijk te werken. Ik heb enkel iconen voor acties gebruikt omdat het zeer snel verkenbaar is. Verder heb ik vooral met grijswaarden gespeeld waar 1 accent kleur naar keuze (in de settings) op ligt.",
                },
            ],
            tags: ["jQuery", "localStorage", "Database", "Authentication"],
            folder: "cheatsheet",
            images: [
                {
                    mobile: "cheatsheet-1.jpg",
                    desktop: "cheatsheet-desktop-1.jpg",
                },
                {
                    mobile: "cheatsheet-2.jpg",
                    desktop: "cheatsheet-desktop-2.jpg",
                },
                {
                    mobile: "cheatsheet-3.jpg",
                    desktop: "cheatsheet-desktop-3.jpg",
                },
                {
                    mobile: "cheatsheet-4.jpg",
                    desktop: "cheatsheet-desktop-4.jpg",
                },
                {
                    mobile: "cheatsheet-5.jpg",
                    desktop: "cheatsheet-desktop-5.jpg",
                },
                {
                    mobile: "cheatsheet-6.jpg",
                    desktop: "cheatsheet-desktop-6.jpg",
                },
                {
                    mobile: "cheatsheet-7.jpg",
                    desktop: "cheatsheet-desktop-7.jpg",
                },
                {
                    mobile: "cheatsheet-8.jpg",
                    desktop: "cheatsheet-desktop-8.jpg",
                },
            ],
            link: "https://cheatsheet-dev.netlify.app/",
        },
        {
            title: "Bingo Cards",
            ket: "bingoCards-4",
            thumbnail: bingoThumb,
            icon: bingoIcon,
            text: [
                {
                    title: "Persoonlijk project: ",
                    text: "Gemaakt voor iemand die graag bingo speelt maar niet snel genoeg het aantal kaartjes kan controlleren. Dus een kleine lokale (via localstorage) Web App die de kaarten genereed, en de nummers aanduid die de caller (op TV/fysiek) opgeeft.",
                },
            ],
            tags: ["JavaScript", "localstorage"],
            folder: "bingo",
            images: [
                {
                    mobile: "bingo-1.png",
                    desktop: "bingo-desktop-1.png",
                },
                {
                    mobile: "bingo-2.png",
                    desktop: "bingo-desktop-2.png",
                },
                {
                    mobile: "bingo-3.png",
                    desktop: "bingo-desktop-3.png",
                },
                {
                    mobile: "bingo-4.png",
                    desktop: "bingo-desktop-4.png",
                },
                {
                    mobile: "bingo-5.png",
                    desktop: "bingo-desktop-5.png",
                },
            ],
            link: "https://bingo-cards-portfolio.netlify.app",
        },
        {
            title: "Scandi Interior",
            key: "scandiInterior-5",
            thumbnail: scandiThumb,
            icon: scandiIcon,
            text: [
                {
                    title: "Persoonlijk project: ",
                    text: "Een rustig project met veel ademruimte. Er is een ontwerp voor een webshop aanwezig, maar sinds het een mockup is zijn 90% van de buttons uitgeschakeld.",
                },
                {
                    title: "Extra info: ",
                    text: "In dit project heb ik vooral aan het ontwerp gewerkt, en niet aan functionaliteiten.",
                },
            ],
            tags: ["jQuery", "CSS", "CSS Grid"],
            folder: "scandi",
            images: [
                {
                    mobile: "scandi-1.jpg",
                    desktop: "scandi-desktop-1.jpg",
                },
                {
                    mobile: "scandi-2.jpg",
                    desktop: "scandi-desktop-2.jpg",
                },
                {
                    mobile: "scandi-3.jpg",
                    desktop: "scandi-desktop-3.jpg",
                },
                {
                    mobile: "scandi-4.jpg",
                    desktop: "scandi-desktop-4.jpg",
                },
                {
                    mobile: "scandi-5.jpg",
                    desktop: "scandi-desktop-5.jpg",
                },
                {
                    mobile: "scandi-6.jpg",
                    desktop: "scandi-desktop-6.jpg",
                },
                {
                    mobile: "scandi-7.jpg",
                    desktop: "scandi-desktop-7.jpg",
                },
                {
                    mobile: "scandi-8.jpg",
                    desktop: "scandi-desktop-8.jpg",
                },
            ],
            link: "https://scandi-interior.netlify.app",
        },
    ];

    let graphicProjects = [
        {
            title: "Twingo Poster",
            key: "twingoPoster-1",
            thumbnail: twingoThumb,
            text: [
                {
                    text: "Een recreatie van een Renault Twingo poster. De poster bevat enkele technieken die zeer handig zijn in het gebruik van Illustrator. Ongesponsord, dus dit is géén reclame.",
                },
            ],
            tags: ["Illustrator", "Vector Brushes"],
            folder: "twingo",
            images: [
                {
                    mobile: "twingo-1.jpg",
                    desktop: "twingo-desktop-1.jpg",
                },
            ],
        },
        {
            title: "Jazz op Vrijdag",
            key: "jazzOpVrijdag-2",
            thumbnail: jazzThumb,
            text: [
                {
                    text: "Een rustige typografische poster die rekening houdt met de regel van 3 in verhoudingen.",
                },
            ],
            tags: ["Illustrator", "Typography"],
            folder: "jazz",
            images: [
                {
                    mobile: "jazz-1.jpg",
                    desktop: "jazz-desktop-1.jpg",
                },
            ],
        },
    ];

    if (props.type === "front-end") {
        jobTitle = "Front-end Developer";
        downloadLink = process.env.PUBLIC_URL + "/downloads/frontend/CV_yensseveryns_210531.pdf";
    } else if (props.type === "uiux") {
        jobTitle = "UI/UX Designer";
        downloadLink = process.env.PUBLIC_URL + "/downloads/uiux/CV_yensseveryns_210531.pdf";
        WebProjects[0]["text"][0]["text"] =
            "Een webapp waar we in een persoonlijke cirkel gebruik van maken. We hielden een lijst bij in Google Sheets met films die we zagen. Die lijst heb ik eerst naar een database laten lopen, om dan verder vanuit de OMDb API data naar de app te laten lopen om dit voor ons een simpeler en aangenamer process te maken.";
        WebProjects[2]["text"][0]["text"] =
            "Documentatie beheren kan soms een rommeltje maken, daarom dacht ik om mijn eigen platform voor mezelf te maken. Hier kan ik mijn documentatie en bronnen bundelen, notities achter laten en snel calculaties uitvoeren om eenheden om te zetten.";
    } else if (props.type === "graphicdesign") {
        jobTitle = "Graphic Designer";
        downloadLink = process.env.PUBLIC_URL + "/downloads/graphicdesign/CV_yensseveryns_210531.pdf";
    }

    return (
        <div className="mywork">
            <header className="dgrey hero-section">
                <div className="svg-wrap">
                    <div id="mywork-hero">
                        <h1>Mijn naam is Yens&nbsp;Severyns</h1>
                        <h2 className="job-title">Ik ben een {jobTitle}</h2>
                        <p>
                            Hier zijn een aantal van mijn projecten om te laten zien wat ik kan. Elk project heeft een reeks van afbeeldingen of een eventuele
                            link naar het project.
                        </p>
                    </div>
                    <div className="hero-illustration-container">
                        <img src={hero} className="hero-illustration" />
                    </div>
                    <div className="btn-container flex center">
                        <NavLink to={`/about/${props.type}`} className="btn">
                            <i className="fas fa-address-card"></i> About
                        </NavLink>
                        <a href={downloadLink} download className="btn" id="btn_resume">
                            <i className="fas fa-download"></i>Download CV
                        </a>
                    </div>
                </div>
            </header>

            <section id="work">
                <div data-animate className="section-title flex center flex-start">
                    <img src="images/icons/front-end-development.svg" alt="Front-end Development" />
                    <h2>Mijn Web Projecten</h2>
                </div>

                {WebProjects &&
                    WebProjects.map((webProject) => {
                        return (
                            <Project
                                key={webProject.key}
                                thumbnail={webProject.thumbnail}
                                icon={webProject.icon}
                                title={webProject.title}
                                text={webProject.text}
                                tags={webProject.tags}
                                folder={webProject.folder}
                                images={webProject.images}
                                link={webProject.link}
                            />
                        );
                    })}

                <div data-animate className="section-title flex center flex-start">
                    <img src="images/icons/graphic-design.svg" alt="Graphic Design" />
                    <h2>Mijn Grafisch Werk</h2>
                </div>

                {graphicProjects &&
                    graphicProjects.map((graphicProject) => {
                        return (
                            <Project
                                key={graphicProject.key}
                                thumbnail={graphicProject.thumbnail}
                                icon={graphicProject.icon}
                                title={graphicProject.title}
                                text={graphicProject.text}
                                tags={graphicProject.tags}
                                folder={graphicProject.folder}
                                images={graphicProject.images}
                                link={graphicProject.link}
                            />
                        );
                    })}
            </section>
        </div>
    );
}

export default WebProjects;
