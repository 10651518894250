import React from "react";
import { NavLink } from "react-router-dom";

function notFound() {
    document.title = "404 Not Found - Yens Severyns";
    return (
        <div className="not-found">
            <section id="intro">
                <header className="hero-section dgrey section-title">
                    <h1>Oeps! </h1>
                    <h2>Het lijkt dat je een ongeldige link hebt gevolgt </h2>
                </header>
                <div className="container">
                    <div className="sub-title">
                        <h3>Maar je kan snel terug naar mijn portfolio gaan.</h3>
                    </div>
                    <div className="btn-container flex center flex-start">
                        <NavLink to="/projects" className="btn flex a-center">
                            <i className="fas fa-briefcase"></i> Projects
                        </NavLink>
                        <NavLink to="/about" className="btn flex a-center">
                            <i className="fas fa-address-card"></i> About
                        </NavLink>
                        <NavLink to="/contact" className="btn flex a-center">
                            <i className="fas fa-comments"></i> Contact
                        </NavLink>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default notFound;
