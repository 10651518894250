import React from "react";
import undrawProfilePic from "../images/illustrations/undraw_profile_pic_ic5t.svg";
import undrawDevelopApp from "../images/illustrations/undraw_develop_app_kvt2.svg";
import undrawDesignTools from "../images/illustrations/undraw_design_tools_42tf.svg";

function About(props) {
    window.scrollTo(0, 0);
    document.title = "About - Yens Severyns";
    let jobTitle = "Graphic/Web Designer";
    let motivation = "Er zijn al wat technologieën waar ik mee kan werken, maar ik heb zeker interesse naar meer!";
    if (props.type === "front-end") {
        jobTitle = "Front-end Developer";
        motivation = "Er zijn al wat technologieën waar ik mee kan werken, maar ik heb zeker interesse naar meer!";
    } else if (props.type === "uiux") {
        jobTitle = "UI/UX Designer";
        motivation = "Ik ben zeker benieuwd naar hoe iedereen test welke UI het beste werkt, of waar bezoekers zich aan ergeren.";
    } else if (props.type === "graphicdesign") {
        jobTitle = "Graphic Designer";
        motivation = "Er zijn al wat technologieën waar ik mee kan werken, maar ik heb zeker interesse naar meer!";
    }

    return (
        <div className="about">
            <section id="intro">
                <header className="hero-section hero-section-sm section-title dgrey">
                    <h1>Een beetje over mezelf: </h1>
                    <h2>En ook een beetje over externe factoren</h2>
                </header>
                <div data-target="intro" className="container container-grid">
                    <div className="container-illustration">
                        <img data-animate src={undrawProfilePic} alt="Profile Illustration" />
                    </div>
                    <div className="container-group">
                        <header data-animate className="sub-title">
                            <h3>Wie ben ik?</h3>
                        </header>
                        <div className="text-block">
                            <p data-animate>
                                Als {jobTitle} heb ik nog steeds een passie voor alle digitale, druk- of geprogrammeerde creaties, daarom ben ik steeds op zoek
                                naar informatie over hoe ik in die velden kan groeien. Dit heeft ervoor gezorgd dat ik snel en flexibel ben in nieuwe
                                onderwerpen aan te leren. Als ik me ergens aan zet, doe ik mijn uiterste best om dit tot een goed einde te brengen.
                            </p>
                        </div>
                    </div>
                </div>
                <div data-target="interests" className="container" id="icons">
                    <div className="container-group">
                        <header data-animate className="sub-title">
                            <h3>Waar heb ik interesse in?</h3>
                        </header>
                        <div className="text-block">
                            <p data-animate>
                                In deze velden heb ik een zéér grote interesse. Hier heb ik altijd tijd voor om meer over te leren of kan ik mij helemaal in
                                verdiepen en ben ik altijd bereid om mij verder in te specialiseren.
                            </p>
                        </div>
                    </div>
                    <div className="container-illustration icon-grid">
                        <div data-animate className="text-block-icon-tag flex center">
                            <img src="images/icons/ui-design.svg" alt="UI Design" />
                            <p>UI Design</p>
                        </div>
                        <div data-animate className="text-block-icon-tag flex center">
                            <img src="images/icons/ux-design.svg" alt="UX Design" />
                            <p>UX Design</p>
                        </div>
                        <div data-animate className="text-block-icon-tag flex center">
                            <img src="images/icons/motion-design.svg" alt="Motion Design" />
                            <p>Motion Design</p>
                        </div>
                        <div data-animate className="text-block-icon-tag flex center">
                            <img src="images/icons/desktop-publishing.svg" alt="Desktop Publishing" />
                            <p>Desktop Publishing</p>
                        </div>
                        <div data-animate className="text-block-icon-tag flex center">
                            <img src="images/icons/graphic-design.svg" alt="Graphic Design" />
                            <p>Graphic Design</p>
                        </div>
                        <div data-animate className="text-block-icon-tag flex center">
                            <img src="images/icons/front-end-development.svg" alt="Front-end Development" />
                            <p>Front-end Development</p>
                        </div>
                    </div>
                </div>
                <div data-target="comp" className="container container-grid">
                    <div className="container-illustration">
                        <img data-animate src={undrawDevelopApp} alt="Checklist Illustration" />
                    </div>
                    <div className="container-group">
                        <header data-animate className="sub-title">
                            <h3>Waar kunnen jullie interesse in hebben?</h3>
                        </header>
                        <div data-animate className="competenties">
                            <p>
                                Omdat ik een junior ben kan ik nog elke kant uitgroeien zonder voorkeur. De frameworks/workflows waar jullie mee werken wil ik
                                zonder problemen overnemen. {motivation}
                            </p>
                        </div>
                    </div>
                </div>
                <div data-target="tools" className="container container-grid">
                    <div className="container-illustration">
                        <img data-animate src={undrawDesignTools} alt="Tools" />
                    </div>
                    <div className="container-group">
                        <header data-animate className="sub-title">
                            <h3>Waar werk ik al mee?</h3>
                        </header>
                        <p data-animate>Dit zijn een aantal programma's en technologieën waar ik vlot mee kan starten:</p>
                        <div className="tools">
                            <h4 data-animate>Web:</h4>
                            <ul>
                                <li data-animate>HTML</li>
                                <li data-animate>CSS (Bootstrap)</li>
                                <li data-animate>JavaScript (Vanilla/jQuery)</li>
                                <li data-animate>React</li>
                                <li data-animate>API</li>
                            </ul>
                            <h4 data-animate>Grafisch:</h4>
                            <ul>
                                <li data-animate>Photoshop</li>
                                <li data-animate>Illustrator</li>
                                <li data-animate>InDesign</li>
                                <li data-animate>Figma/Adobe XD/Sketch</li>
                                <li data-animate>Premiere (basis)</li>
                                <li data-animate>After Effects (basis)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;
