import React, { useState } from "react";
import Modal from "../../Modal";

function Project(props) {
    const [open, setOpen] = useState(false);
    const { thumbnail, icon, title, text, tags, link, folder, images } = props;

    const Icon = () => {
        if (icon != null) {
            return <img src={icon} alt="Project icon" />;
        } else {
            return "";
        }
    };

    const Link = () => {
        if (link != null) {
            return (
                <div data-animate className="card-button-container">
                    <a className="card-link btn" data-link={"clicked"} data-target={title} href={link} target="_blank" rel="noreferrer noopener">
                        <i className="fas fa-external-link-alt"></i> Bekijk Project
                    </a>
                </div>
            );
        } else {
            return "";
        }
    };

    return (
        <div className="card container" data-target={title}>
            <div className="card-img">
                <img data-animate src={thumbnail} alt="project thumbnail" />
            </div>
            <div className="card-content">
                <button
                    data-animate
                    onClick={(() => setOpen(true))}
                    data-link={"images"}
                    data-target={title}
                    className="card-img-toggle btn flex center"
                >
                    <i className="fas fa-images"></i> Bekijk Afbeeldingen
                </button>
                <div data-animate className="card-title flex center flex-start">
                    <Icon />
                    <h4>{title}</h4>
                </div>
                <div className="card-desc">
                    {text &&
                        text.map((el, index) => {
                            return (
                                <p data-animate key={index}>
                                    <strong>{el.title}</strong>
                                    {el.text}
                                </p>
                            );
                        })}
                </div>
                <div className="card-tag-container flex">
                    {tags &&
                        tags.map((el, index) => {
                            return (
                                <div data-animate className="card-tag" key={index}>
                                    {el}
                                </div>
                            );
                        })}
                </div>
                <Link />
            </div>

            <Modal open={open} onClose={() => setOpen(false)}>
                {images &&
                    images.map((image, index) => {
                        return (
                            <div key={index} className={index > 0 ? "modal-image-wrap" : "modal-image-wrap active"}>
                                <picture>
                                    <source media="(orientation: landscape)" srcSet={"../images/isotopes/" + folder + "/" + image.desktop} />
                                    <img className="modal-image" src={"../images/isotopes/" + folder + "/" + image.mobile} alt="Project Modal" />
                                </picture>
                            </div>
                        );
                    })}
            </Modal>
        </div>
    );
}

export default Project;
