import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function navToggleActive(e) {
    document.querySelector(".nav-toggle").classList.toggle("navigating");
    let offset = window.scrollY;
    let body = document.querySelector("body");
    let navWrap = document.querySelector("#nav-wrap");
    if (navWrap.classList.contains("closed")) {
        navWrap.classList.remove("closed");
        navWrap.classList.add("visible");
        body.classList.add("overflow-hidden");
        /* body.style.top = -offset + "px"
        body.setAttribute('data-value', offset) */
    } else {
        navWrap.classList.add("closed");
        navWrap.classList.remove("visible");
        body.classList.remove("overflow-hidden");
        /* body.style.top = 0;
        let scrollToPos = body.getAttribute('data-value');
        window.scrollTo(0, scrollToPos) */
    }
}

const NavToggle = ({ white }) => (
    <button className="nav-toggle" aria-label="Navigation toggle" onClick={navToggleActive}>
        <svg version="1.1" viewBox="0 0 16.1 16.1" fill={white}>
            <g id="Button">
                <g>
                    <defs>
                        <circle id="SVGID_17_" cx="8" cy="8" r="8" />
                    </defs>
                    <clipPath id="SVGID_1_">
                        <use style={{ overflow: "visible" }} />
                    </clipPath>
                    <circle id="nav-toggle-background" cx="8" cy="8" r="8" />
                    <rect className="hamburger-1" x="4.8" y="5.6" fill={white} width="6.4" height="1" />
                    <rect className="hamburger-2" x="4.8" y="7.5" fill={white} width="6.4" height="1" />
                    <rect className="hamburger-3" x="4.8" y="9.5" fill={white} width="6.4" height="1" />

                    <rect className="cross-left" x="4.8" y="7.5" fill={white} width="6.4" height="1" />

                    <rect className="cross-right" x="4.8" y="7.5" fill={white} width="6.4" height="1" />
                </g>
            </g>
        </svg>
    </button>
);

function navClicked(e) {
    let active = document.querySelector(".active");
    if (active != null) {
        active.classList.remove("active");
    }
    e.target.classList.add("active");
}

function Nav(props) {

    let jobTitle = "Graphic/Web Designer";
    if (props.type === "front-end") {
        jobTitle = "Front-end Developer";
    } else if (props.type === "uiux") {
        jobTitle = "UI/UX Designer";
    } else if (props.type === "graphicdesign") {
        jobTitle = "Graphic Designer";
    }

    return (
        <div id="nav">
            <div id="nav-wrap" className="closed">
                <div className="nav-content">
                    <div className="nav-header">
                        <div id="profile-illustration" className="flex center">
                            <svg viewBox="0 0 511 511">
                                <circle fill="var(--white)" cx="255.5" cy="255.5" r="232.46" />
                                <path
                                    fill="var(--accent)"
                                    d="M206.74,378.42v8.86h62a35.49,35.49,0,0,0,35.48-35.48v-8.86h-62A35.48,35.48,0,0,0,206.74,378.42Z"
                                />
                                <path fill="var(--accent)" d="M206.74,434.3v6.12H266a38.22,38.22,0,0,0,38.22-38.22v-6.12H245A38.22,38.22,0,0,0,206.74,434.3Z" />
                                <path
                                    fill="var(--accent)"
                                    d="M354.64,156.38a115.41,115.41,0,0,0-198.28,0h0a115.45,115.45,0,0,0,36.29,155.91l.42.27.45.24a46.83,46.83,0,0,1,4.15,2.53,47.45,47.45,0,0,1,13.14,13.46,12.15,12.15,0,1,0,20.4-13.22,71.6,71.6,0,0,0-19.81-20.3,67.53,67.53,0,0,0-5.89-3.61A91.12,91.12,0,1,1,333.77,168.84h0a91.16,91.16,0,0,1-28.25,122.8,66.25,66.25,0,0,0-5.88,3.61,71.28,71.28,0,0,0-19.85,20.32,12.15,12.15,0,0,0,20.4,13.22,47.33,47.33,0,0,1,13.19-13.49,42.56,42.56,0,0,1,4.12-2.51l.45-.24.44-.28a115.46,115.46,0,0,0,36.25-155.89Z"
                                />
                                <path
                                    fill="var(--accent)"
                                    d="M303.48,212.92a12.17,12.17,0,0,0-17.19,0l-30.79,30.8-30.79-30.8a12.16,12.16,0,1,0-17.19,17.19l35.83,35.83v23.54a12.15,12.15,0,1,0,24.3,0V265.94l35.83-35.83A12.15,12.15,0,0,0,303.48,212.92Z"
                                />
                            </svg>
                        </div>
                        <div className="section-title flex center column">
                            <h1>Yens Severyns</h1>
                            <h2>{jobTitle}</h2>
                        </div>
                    </div>
                    <div className="nav-list">
                        <div className="nav-item">
                            <NavLink onClick={navClicked} to={`/projects/${props.type}`} className="nav-link flex a-center">
                                <i className="fas fa-briefcase"></i> Projects
                            </NavLink>
                        </div>
                        <div className="nav-item">
                            <NavLink onClick={navClicked} to={`/about/${props.type}`} className="nav-link flex a-center">
                                <i className="fas fa-address-card"></i> About
                            </NavLink>
                        </div>
                    </div>

                    {/* <div className="section-title">
                        <h4>
                            <i className="fas fa-comments"></i>
                            Contact
                        </h4>
                    </div>
                    <div className="nav-item">
                        <a className="nav-link flex a-center" href="mailto:yens.severyns@gmail.com">
                            <i className="fas fa-envelope"></i> yens.severyns@gmail.com
                        </a>
                    </div>
                    <div className="nav-item">
                        <a className="nav-link flex a-center" href="tel:0476756587">
                            <i className="fas fa-mobile"></i> 0476 75 65 87
                        </a>
                    </div> */}
                </div>
                <div onClick={navToggleActive} className="nav-wrap-close"></div>
            </div>
            <NavToggle white="white" />
        </div>
    );
}
export default Nav;
