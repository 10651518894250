import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';

export default function Modal({ open, onClose, children }) {        
    const [modalClass, setModalClass] = useState('');
    const [dots, setDots] = useState([]);
    
    useEffect(() => {
        if (open){
            document.querySelector('body').classList.add('overflow-hidden');
            let imageLength = document.querySelectorAll('.modal-image-wrap').length;
            let dotslength = [];
            for (let i = 0; i < imageLength; i++){
                dotslength.push(i);
            }
            setDots(dotslength);
            setModalClass('viewing-image');
        }
        if (!open) {
            document.querySelector('body').classList.remove('overflow-hidden');
        }
    }, [open]);

    if (!open) return null
   
    function CloseModal(e) {
        setModalClass('');
        setTimeout(() => {
            onClose();
        }, 600);
    }

    function getIndex(target) {
        let images = document.querySelectorAll(target);
        let index;
        for (let i = 0; i < images.length; i++){
            if (images[i].classList.contains('active')){
                index = i;
                break;
            }
        }
        return index
    }

    function getDot(e) {
        let dotIndex = e.target.getAttribute('index');
        let index = getIndex('.modal-image-wrap');
        let images = document.querySelectorAll('.modal-image-wrap');
        let dots = document.querySelectorAll('.modal-image-dot');
        images[index].classList.remove('active');
        dots[index].classList.remove('active');
        images[dotIndex].classList.add('active');
        dots[dotIndex].classList.add('active');
        document.querySelector('.modal-image-container').style.transform = "translate(-" + (dotIndex) + "00%, 0)"
    }

    function Next() {
        let index = getIndex('.modal-image-wrap');
        let images = document.querySelectorAll('.modal-image-wrap');
        let dots = document.querySelectorAll('.modal-image-dot');

        let imagesL = images.length;
        let currentImage = index,
            nextImage = index + 1;


        if (index + 1 < imagesL){
            document.querySelector('.modal-image-container').style.transform = "translate(-" + (index + 1) + "00%, 0)"
            images[index].classList.remove('active');
            dots[index].classList.remove('active');
            images[index + 1].classList.add('active');
            dots[index + 1].classList.add('active');
        } else {
            document.querySelector('.modal-image-container').style.transform = "translate(0px, 0)"
            nextImage = 0;
            images[index].classList.remove('active');
            dots[index].classList.remove('active');
            images[0].classList.add('active');
            dots[0].classList.add('active');
        }
    }
    function Prev() {
        let index = getIndex('.modal-image-wrap');
        let images = document.querySelectorAll('.modal-image-wrap');
        let dots = document.querySelectorAll('.modal-image-dot');

        let imagesL = images.length;
        images[index].classList.remove('active');
        dots[index].classList.remove('active');
        if (index > 0){
            document.querySelector('.modal-image-container').style.transform = "translate(-" + (index - 1) + "00%, 0)"
            images[index - 1].classList.add('active');
            dots[index - 1].classList.add('active');
        } else {
            document.querySelector('.modal-image-container').style.transform = "translate(-" + (imagesL - 1) + "00%, 0)"
            images[imagesL - 1].classList.add('active');
            dots[imagesL - 1].classList.add('active');
        }
    }

    return ReactDom.createPortal(
        <div id='modal' className={"flex center " + modalClass} tabIndex="0">
            <div className="modal-image-container flex center">
                <div id="track" style={{width: dots.length + '00%'}}>
                    {children}
                </div>
            </div>

            <div className="modal-btn-container">
                <div className="modal-btn-wrap flex center">
                    <div onClick={Prev} className="modal-btn modal-image-prev flex center">
                        <i className="fas fa-arrow-left"></i>
                    </div>
                    <div className="modal-image-counter flex center">
                        {
                            dots.map((index) => {
                                return (
                                    <div className={index > 0 ? 'modal-image-dot' : 'modal-image-dot active'}
                                         key={index}
                                         index={index}
                                         onClick={getDot}>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div onClick={Next} className="modal-btn modal-image-next flex center">
                        <i className="fas fa-arrow-right"></i>
                    </div>
                    <div onClick={CloseModal} className="modal-btn close-modal flex center">
                        <i className="fas fa-times"></i>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('portal')
    )
}