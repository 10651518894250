import { NavLink } from "react-router-dom";

function Footer(props) {
    return (
        <footer className="flex center column dgrey">
            <div className="footer-wrap">
                <h3>Twijfel niet om me te contacteren!</h3>
                <p>
                    Tijdens de opleiding, en het IBO-contract waren ze overtuigd van mijn motivatie, werkattitude en inzet. Misschien kan ik jullie ook wel
                    overtuigen.
                </p>
            </div>
        </footer>
    );
}

export default Footer;
